import { useEffect, useState } from 'react';

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);
  const [isAndroid, setIsAndroid] = useState();
  const [isIos, setIsIos] = useState();

  useEffect(() => {
    const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
    const mobile = Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
    const android = Boolean(userAgent.match(/android/i));
    const ios = Boolean(userAgent.match(/iPad|iPhone|iPod/i)) && !window.MSStream;

    setMobile(mobile);
    setIsAndroid(android);
    setIsIos(ios);
  }, []);

  return { isMobile, isAndroid, isIos };
}
