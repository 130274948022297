import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import { ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import get from 'lodash/get';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState, Suspense } from 'react';
import { BiBarcodeReader } from 'react-icons/bi';
import { RiErrorWarningFill } from 'react-icons/ri';

import authAPI from '../api/wave/auth';
import { PrimaryButton, SecondaryButton } from '../components/Buttons/Buttons';
import CustomHead from '../components/CustomHead';
import MainLogo from '../components/MainLogo/MainLogo';
import OTPView from '../components/OTPView/OTPView';
import useDeviceDetect from '../hooks/useDeviceDetect';
import GuestUserStore from '../store/guestUser';
import Loader from '../store/loader';
import User from '../store/user';
import { addToAnalyticsGenericEvent } from '../utils/analytics';
import { theme } from '../utils/themeProvider';
import verification from '../utils/verifications';

import styles from './login.module.scss';

const DEFAULT_ERROR_MESSAGE = 'Κάτι πήγε στραβά. Παρακαλώ προσπαθήστε ξανά.';

const PAGE_CONFIG = {
  digital: {
    headerText: 'Συνδέσου στο λογαριασμό σου',
    inputText: 'Αριθμός Κάρτας',
    mainText:
      'Συνδέσου τώρα εισάγοντας τον αριθμό της κάρτας σου, για να σου στείλουμε με SMS τον κωδικό επιβεβαίωσης.',
    hasSeparator: false,
    hasLoginLink: true,
    regexPattern: process.env.cardPattern,
    regexErrorMessage: 'O αριθμός κάρτας είναι λάθος.',
    otpMethod: 'login-digital',
  },
  notDigital: {
    headerText: 'Σύνδεση στον λογαριασμό σου',
    inputText: 'Κινητό τηλέφωνο ή αριθμός κάρτας',
    mainText:
      'Συνδέσου τώρα εισάγοντας τον αριθμό κινητού σου ή την κάρτα σου, για να σου στείλουμε με SMS τον κωδικό επιβεβαίωσης.',
    hasSeparator: true,
    hasLoginLink: false,
    regexPattern: '^(69\\d{8}|\\d{12,13})$',
    regexErrorMessage: 'Το κινητό τηλέφωνο ή ο αριθμός κάρτας είναι λάθος.',
    otpMethod: 'login',
  },
};

const QRScannerLoginWrapper = dynamic(() => import('../components/QRScaner/QRScannerLoginWrapper'), {
  suspense: true,
});

export default function Login() {
  const [id, setId] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [phoneError, setPhoneError] = useState(false);
  const [errorMsg, setErrorMsg] = useState(' ');
  const [step, setStep] = useState('login');
  const [configuration, setConfiguration] = useState(PAGE_CONFIG.notDigital);
  const router = useRouter();
  const [isDigital, setIsDigital] = useState(false);
  const [openScanner, setOpenScanner] = useState(false);

  useEffect(() => {
    if (router.isReady && router.query?.isDigital) {
      if (router.query.isDigital === 'digital') {
        setIsDigital(true);
      }
    }
  }, [router.query.isDigital]);

  useEffect(() => {
    if (isDigital) {
      setConfiguration(PAGE_CONFIG[router.query.isDigital]);
    }
  }, [isDigital]);

  function onClickLogin() {
    setConfiguration(PAGE_CONFIG.notDigital);

    setIsDigital(!isDigital);
    setMobileNumber('');
    setPhoneError(false);
    setErrorMsg('');

    router.push(
      {
        pathname: router.asPath.split('?')[0],
      },
      undefined,
      { shallow: true }
    );
  }
  const { isMobile } = useDeviceDetect();

  function successRedirect() {
    router.push('/digital-card-success/?origin=login', '/digital-card-success/?origin=login');
  }

  function onSubmitPhone(e) {
    e.preventDefault();
    setPhoneError(false);
    setErrorMsg(configuration.regexErrorMessage);
    if (GuestUserStore.getValue()) {
      GuestUserStore.setInitUser();
    }

    if (!id) {
      setPhoneError(true);
    }

    addToAnalyticsGenericEvent('login');
    requestOTP();
  }

  function onBlurPhone(loginId) {
    const numberIdNoSpace = loginId.replace(/\s/g, '');
    const parsePattern = new RegExp(configuration.regexPattern);
    const isValid = numberIdNoSpace.match(parsePattern);
    if (!isValid) {
      setPhoneError(true);
      setErrorMsg(configuration.regexErrorMessage);
    } else {
      setPhoneError(false);
      setErrorMsg('');
    }
  }

  function requestOTP() {
    const numberIdNoSpace = id.replace(/\s/g, '');
    const isValid = numberIdNoSpace.match(configuration.regexPattern);

    if (!isValid) {
      setPhoneError(true);
      setErrorMsg(configuration.regexErrorMessage);
      return;
    }
    Loader.addEvent('LOGIN');
    const verificationMethod = verification.isCardNumber(numberIdNoSpace) ? 'card' : 'mobile';
    authAPI
      .loginSendOTP(numberIdNoSpace, verificationMethod)
      .then(response => {
        User.update({ maskedMobile: response.payload.verification.mobile });
        setMobileNumber(response.payload.verification.verificationToken);
        setStep('otp');
        setPhoneError(false);
        setErrorMsg(' ');
      })
      .catch(error => {
        const errorMessage = get(error, 'response.data.error.userMessage', DEFAULT_ERROR_MESSAGE);
        const errorCode = get(error, 'response.data.error.code');
        if (errorCode === 'USRINT063') {
          User.update({ cardId: numberIdNoSpace });
          if (isDigital) {
            router.push('/digital-card-renew', '/digital-card-renew');
            return;
          }
          router.push('/register', '/register');
          return;
        }
        User.update({ cardId: '' });
        setPhoneError(true);
        setErrorMsg(errorMessage);
      })
      .finally(() => {
        Loader.removeEvent('LOGIN');
      });
  }

  function onChangePhone(e) {
    const newValue = e.target.value.replace(/[^\d\s]/, '');
    setId(newValue);
  }

  function onOpenScanner() {
    setOpenScanner(true);
  }

  function onScanSuccess(data) {
    const object = JSON.parse(data);
    const newId = JSON.stringify(object);
    setId(newId);
    setOpenScanner(false);
    onBlurPhone(newId);
  }

  function onScanClose() {
    setOpenScanner(false);
  }

  const hasIcons = phoneError || (isMobile && isDigital);
  return (
    <section className={styles.container}>
      <CustomHead title="Σύνδεση/ Εγγραφή" />
      <div className={styles.logoContainer}>
        <Link href="/">
          <a>
            <MainLogo />
          </a>
        </Link>
      </div>
      <h1 className={styles.header}>{configuration.headerText} </h1>
      {step === 'login' && (
        <>
          <div className={styles.imageContainer}>
            <img
              className={styles.img}
              width="200"
              height="200"
              src={`/assets/${process.env.appName}/${process.env.appName}-login.png`}
              alt="grocery app"
            />
          </div>
          {step === 'login' && <p className={styles.p}>{configuration.mainText}</p>}
          <form noValidate className={styles.fullWidth} onSubmit={onSubmitPhone}>
            <Box sx={phoneError ? { height: 'auto', minHeight: '5.5rem' } : { height: '5.5rem' }}>
              <ThemeProvider theme={theme}>
                <TextField
                  label={configuration.inputText}
                  name="phone"
                  variant="outlined"
                  onChange={onChangePhone}
                  onBlur={() => onBlurPhone(id)}
                  fullWidth
                  required
                  error={phoneError}
                  value={id}
                  helperText={errorMsg}
                  data-cy="userId"
                  InputProps={{
                    endAdornment: hasIcons && (
                      <>
                        {phoneError && (
                          <InputAdornment position="end">
                            <RiErrorWarningFill className={styles.waringIcon} />
                          </InputAdornment>
                        )}
                        {isMobile && isDigital && (
                          <InputAdornment position="end">
                            <BiBarcodeReader
                              className={styles.barCodeIcon}
                              title="barCodeClick"
                              onClick={onOpenScanner}
                            />
                          </InputAdornment>
                        )}
                      </>
                    ),
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  inputProps={{
                    inputMode: 'numeric',
                    pattern: '[0-9]*',
                  }}
                />
              </ThemeProvider>
            </Box>
            <div className={styles.primaryButtonContainer}>
              <PrimaryButton type="submit" data-cy="request-otp" style={{ width: '100%' }} disabled={!id || phoneError}>
                Συνέχεια
              </PrimaryButton>
            </div>
          </form>
          {configuration.hasSeparator && (
            <div className={styles.orTextContainer}>
              {process.env.hasGuest ? (
                <p className={styles.orText}>ή συνέχισε με έναν από τους παρακάτω τρόπους</p>
              ) : (
                <p className={styles.orTextWithLines}>Εναλλακτικά κάνε εγγραφή σε 1 λεπτό!</p>
              )}
            </div>
          )}
          {configuration.hasLoginLink ? (
            <a className={styles.registerLink} data-cy="login-digital" onClick={() => onClickLogin()}>
              Έχω ήδη λογαριασμό στα Supermarket {process.env.displayName}
            </a>
          ) : (
            <Link href="/register">
              <a className={styles.fullWidth}>
                <SecondaryButton onClick={() => {}} data-cy="register" style={{ width: '100%', marginBottom: '1rem' }}>
                  Εγγραφή
                </SecondaryButton>
              </a>
            </Link>
          )}
          {process.env.hasGuest && !isDigital && (
            <Link href="/guest">
              <a className={styles.fullWidth}>
                <SecondaryButton onClick={() => {}} data-cy="guest" style={{ width: '100%' }}>
                  Συνέχεια ως επισκέπτης
                </SecondaryButton>
              </a>
            </Link>
          )}
        </>
      )}
      {step === 'otp' && (
        <OTPView userId={mobileNumber} method={configuration.otpMethod} successCallback={successRedirect} />
      )}
      {openScanner && (
        <Suspense fallback={<p />}>
          <QRScannerLoginWrapper qrCodeSuccessCallback={onScanSuccess} onClose={onScanClose} />
        </Suspense>
      )}
    </section>
  );
}
